@charset "utf-8";

// COLORS
$primary: #00d1b2;
$info: #209cee;
$success: #23d160;
$warning: #ffdd57;
$danger: #ff3860;
$light: #f5f5f5;
$dark: #363636;

// TEXT
$text: #4a4a4a;
$link: #3273dc;
$body-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  'Helvetica', 'Arial', sans-serif;
$title-family: false;
$button-family: false;

// BREAKPOINTS
$gap: 32px;
$tablet: 769px;
$desktop: 960px + (2 * $gap);
$widescreen: 1152px + (2 * $gap);
$fullhd: 1344px + (2 * $gap);
$widescreen-enabled: true;
$fullhd-enabled: false;

// LAYOUT
$section-padding: 3rem 1.5rem;
$section-padding-medium: 6rem 1.5rem;
$section-padding-large: 9rem 1.5rem;

// SPACING HELPERS (.mt-3, .mx-auto, .p-5, etc)
$spacing-shortcuts: (
  'margin': 'm',
  'padding': 'p',
);
$spacing-directions: (
  'top': 't',
  'right': 'r',
  'bottom': 'b',
  'left': 'l',
);
$spacing-horizontal: 'x';
$spacing-vertical: 'y';
$spacing-values: (
  '0': 0,
  '1': 0.25rem,
  '2': 0.5rem,
  '3': 1rem,
  '4': 1.5rem,
  '5': 3rem,
  'auto': auto,
);

// SEE DOCS FOR MORE:
// https://bit.ly/30UvE5O

// IMPORT BULMA

// IMPORT FONT AWESOME
@import url('https://use.fontawesome.com/releases/v5.10.1/css/all.css');
* {
  outline: none !important;
  font-family: 'Montserrat', sans-serif!important;
}
button {
  outline: none!important;
  box-shadow: none!important;
}
body {
  // Fix Bulma bug where columns cause
  // horizontal scroll on mobile.
  // https://github.com/jgthms/bulma/issues/449
  overflow: hidden;
  color: #000000;
  font-size: 15px;
}

#root {
  // Full height of screen and flex so that we can push footer
  // to the bottom even if content is shorter than screen
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // Push footer to the bottom of the screen
  > .footer {
    margin-top: auto;
  }
  // Prevent child elements from shrinking when content
  // is taller than the screen (quirk of having flex parent)
  > * {
    flex-shrink: 0;
  }
}

td {
  text-align: left !important;
  vertical-align: middle !important;
}

.chGreen {
  filter: brightness(0) invert(1);
}

.history::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.history::-webkit-scrollbar-track {
  background: #dedcdc;
  border-radius: 5px;
}

/* Handle */
.history::-webkit-scrollbar-thumb {
  background: #989898;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #828181;
}
.slHover:focus {
  border: 1px solid #01008F !important;
}
.slHover:hover {
  border: 1px solid #01008F !important;
}

::placeholder {
 color: rgba(152, 152, 152, 1); font-size: 14px; font-weight: 400; line-height: 25px;
}
.rSelect {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  border:1px solid rgb(152, 152, 152);
  background-position: calc(100% - 15px) center !important;
  background: url('../assets/svgs/greenDrop.svg') no-repeat;
}
select {
 border: 1px solid rgba(152, 152, 152, 1);
}
.noScrollBar::-webkit-scrollbar {
  width: 0px !important;
}
body::-webkit-scrollbar {
  width: 0px !important;
}

// Loader styles

@keyframes animateLoader {
  0% {
    background: rgba(0, 0, 0, 0.08);
    width: 50%;
  }
  50% {
    background: rgba(0, 0, 0, 0.09);
    width: 43%;
  }
  75% {
    background: rgba(0, 0, 0, 0.085);
    width: 46%;
  }
  100% {
    background: rgba(0, 0, 0, 0.08);
    width: 50%;
  }
}

.loader {
  width: 50%;
  animation-name: animateLoader;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

@keyframes animateLoader2 {
  0% {
    background: rgba(0, 0, 0, 0.08);
    width: 40%;
  }
  50% {
    background: rgba(0, 0, 0, 0.095);
    width: 33%;
  }
  75% {
    background: rgba(0, 0, 0, 0.085);
    width: 36%;
  }
  100% {
    background: rgba(0, 0, 0, 0.09);
    width: 40%;
  }
}

.loader2 {
  width: 40%;
  animation-name: animateLoader2;
  animation-iteration-count: infinite;
  animation-duration: 5s;
}

@keyframes animateLoader3 {
  0% {
    background: rgba(0, 0, 0, 0.08);
    width: 20%;
  }
  50% {
    background: rgba(0, 0, 0, 0.09);
    width: 13%;
  }
  75% {
    background: rgba(0, 0, 0, 0.1);
    width: 16%;
  }
  100% {
    background: rgba(0, 0, 0, 0.08);
    width: 20%;
  }
}

.loader3 {
  width: 40%;
  animation-name: animateLoader3;
  animation-iteration-count: infinite;
  animation-duration: 5s;
}
